<template>
  <div class="main index" v-if="!isLoading">
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(processFormRegister)">
        <div>
          <div class="columns is-multiline">
            <div class="column is-12">
              <h1 class="is-size-3"><b>Settings</b></h1>
            </div>
            <div class="column is-10">
              <nav class="breadcrumb" aria-label="breadcrumbs">
                <ul>
                  <li>
                    <h3 class="is-size-6">Setting</h3>
                  </li>
                  <li>
                    <h3 class="is-size-6 breadcrumb-item">
                      Psikotest Introduction
                    </h3>
                  </li>
                </ul>
              </nav>
            </div>
            <div class="column is-2">
              <b-field grouped position="is-right">
                <b-button type="is-hcc" native-type="submit">Save</b-button>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column is-12">
              <div class="box">
                <!-- For Banner Image Field -->
                <div class="columns">
                  <div class="column is-2">
                    <b>Add Banner</b>
                  </div>
                  <div class="column is-10">
                    <b-field class="file">
                      <span class="file-name">{{
                        psikotestIntroduction.file.name
                      }}</span>
                      <b-upload
                        v-model="psikotestIntroduction.file"
                        accept="image/*"
                      >
                        <a class="button is-hcc">
                          <b-icon size="is-small" icon="upload"></b-icon>
                          <span>Upload</span>
                        </a>
                      </b-upload>
                    </b-field>
                  </div>
                </div>

                <!-- For Title Field -->
                <div class="columns is-12">
                  <div class="column is-2">
                    <b>Title</b>
                    <b-tooltip type="is-danger" label="This field is required">
                      <span class="required">*</span>
                    </b-tooltip>
                  </div>
                  <div class="column is-10">
                    <ValidationProvider
                      name="title"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <b-input v-model="psikotestIntroduction.title"></b-input>
                      <span class="required">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>

                <!-- For Rules Description -->
                <div class="columns is-12">
                  <div class="column is-2">
                    <b>Rules</b>
                    <b-tooltip type="is-danger" label="This field is required">
                      <span class="required">*</span>
                    </b-tooltip>
                  </div>
                  <div class="column is-10">
                    <ValidationProvider
                      name="rules"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <ckeditor
                        v-model="psikotestIntroduction.rules"
                        :config="editorConfig"
                      />
                      <span class="required">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  name: "Register",
  data() {
    return {
      isLoading: true,
      psikotestIntroduction: {
        title: null,
        rules: null,
        file: {
          name: null
        }
      },
      editorConfig: {
        // The configuration of the editor.
        extraAllowedContent: "div[class]",
        allowedContent: true,
        // filebrowserUploadMethod: "form",
        // filebrowserUploadUrl: process.env.VUE_APP_BACKEND_URL + "/api/ckeditor/upload-image",
        extraPlugins: "justify,font"
      }
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.isLoading = true;
      const response = await this.$store.dispatch("getPsikotestIntroduction");
      if (response.message != "Data Not Found") {
        let file = JSON.parse(response.data.bannerImages);
        this.psikotestIntroduction = {
          title: response.data.title,
          rules: response.data.rules,
          file: {
            name: file.file_name
          }
        };
      }
      this.isLoading = false;
    },
    processFormRegister() {
      this.$store
        .dispatch("storePsikotestIntroduction", this.psikotestIntroduction)
        .then(response => {
          if (response.data.status == 406) {
            this.danger(response.data.message);
          } else {
            this.success("Successfully save psikotest introduction");
          }
        })
        .catch(() => {
          this.danger("Failed to create data");
        });
    },
    success(message) {
      this.$buefy.toast.open({
        message: message,
        type: "is-success"
      });
    },
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: "is-danger"
      });
    }
  }
};
</script>
